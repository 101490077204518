<template>
    <div>
        <header class="header">
            <router-link to="/" class="logo">Infinite Cheddar</router-link>
            <ul class="nav-menu">
                <li><router-link to="/about">About Us</router-link></li>
                <li><router-link to="/blog">Blog</router-link></li>
                <li><router-link to="/grocery-deals">Grocery Deals</router-link></li>
            </ul>
            <router-link to="/login" class="btn">Login / Signup</router-link>
        </header>

        <section class="banner">
            <div class="overlay"></div>
            <div class="banner-content">
                <h1 class="banner-title">Save Time and Money on Grocery Shopping</h1>
                <p class="banner-subtitle">Infinite Cheddar helps you find the best deals and manage your grocery list effortlessly.</p>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'HomePage',
};
</script>
